<script>
export default {
    name: "Modal",
    props: {
        title: {
            type: String,
            required: true,
        },
        visibility: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: "50",
        },
        showClose: {
            type: Boolean,
            default: true,
        },
        escToClose: {
            type: Boolean,
            default: true,
        },
        destroyOnClose: {
            type: Boolean,
            default: false,
        },
        center: {
            type: Boolean,
            default: true
        },
    },
};
</script>

<template lang="pug">
el-dialog(
    :title="title",
    v-model="visibility",
    :width="`${width}%`",
    :show-close="showClose",
    :destroy-on-close="destroyOnClose"
    @close="$emit('close')"
)
    slot
</template>