<script>
import FormFieldInput from "@components/form-field-input";

export default {
    name: "ManageRecharge",
    components: {
        FormFieldInput,
    },
    emits: ['addBalance'],
    data() {
        return {
            amount: null,
            note: "",
            methods: [
                { label: "Cheque", value: "cheque" },
                { label: "RTGS", value: "rtgs" },
                { label: "Other", value: "other" },
            ],
            paymentMethod: "",
        };
    },
    methods: {
        submit() {
            if(!this.amount) {
                return this.$utility.showErrorMessage("Amount is required!")
            }
            if(!this.paymentMethod) {
                return this.$utility.showErrorMessage("Payment method is required!")
            }

            this.$emit('addBalance', {
                amount: this.amount,
                note: this.note,
                paymentMethod: this.paymentMethod,
            })
        }
    }
};
</script>

<template lang="pug">
.flex.justify-center.items-center.flex-col
    form-field-input(
        inputType="textnumber",
        placeholder="Enter amount in ₹",
        attrinbute="amount",
        v-model="amount",
        :maxLength="15",
        label="Total Amount"
    )
    form-field-input(
        inputType="select",
        placeholder="Select Payment Method",
        attrinbute="paymentMethod",
        v-model="paymentMethod",
        :required="true",
        label="Payment Method",
        labelKey="label",
        valueKey="value",
        :items="methods",
        :clearable="true"
    ) 
    form-field-input(
        inputType="textarea",
        placeholder="Enter note if any...",
        attrinbute="note",
        v-model="note",
        label="Note to dealer"
    )
    .btn(@click="submit")
        el-button(type="success" plain ) Add
</template>
